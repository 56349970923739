.adm-card {
  background: var(--adm-color-background);
  border-radius: 8px;
  padding: 0 12px;
}
.adm-card-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 0;
}
.adm-card-header:not(:last-child) {
  border-bottom: solid 0.5px var(--adm-color-border);
}
.adm-card-header-title {
  font-size: var(--adm-font-size-7);
  line-height: 1.4;
  font-weight: bold;
}
.adm-card-body {
  padding: 12px 0;
}
